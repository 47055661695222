import butter from "../../utils/butter-client";
import { useEffect, useState, useRef } from "react";
import Close from "../../assets/svg/components/Close.svg";
import { AnimatePresence, motion } from "framer-motion";
import { animatePresenceConfig } from "../../../config/appConfig";

export const Banner = () => {
  const [hidden, setHidden] = useState(null);
  const [index, setIndex] = useState(0);
  const [headerBannerArray, setHeaderBannerArray] = useState([]);

  useEffect(() => {
    setHidden(sessionStorage.getItem("demystBannerClosed"));
  }, []);

  useEffect(() => {
    if (!hidden) {
      document.getElementsByClassName("layout-main")[0].classList.add("banner-open");
      document.getElementsByClassName("layout-header-full")[0].classList.add("banner-open");
    } else {
      document.getElementsByClassName("layout-main")[0].classList.remove("banner-open");
      document.getElementsByClassName("layout-header-full")[0].classList.remove("banner-open");
    }
  }, [hidden]);

  useEffect(() => {
    butter.page
      .retrieve("*", "website-banner")
      .then(response => {
        const { data } = response.data;
        const headerBanner = data?.fields?.header_banner;
        headerBanner.forEach((item, i) => {
          const buttons = [];
          const { button_text_1, button_text_2, button_url_1, button_url_2 } = headerBanner[i];
          if (button_text_1 && button_url_1) {
            buttons.push({
              text: button_text_1,
              href: button_url_1,
            });
          }
          if (button_text_2 && button_url_2) {
            buttons.push({
              text: button_text_2,
              href: button_url_2,
            });
          }
          headerBanner[i] = {
            buttons,
            ...headerBanner[i],
          };
        });
        setHidden(headerBanner.length < 1 || sessionStorage.getItem("demystBannerClosed"));

        setHeaderBannerArray(headerBanner);
      })
      .catch(err => {
        console.log(err);
        setHidden(true);
      });
  }, []);

  const savedCallback = useRef();

  const callback = () => {
    let nextIndex = index + 1;
    if (nextIndex >= headerBannerArray.length) nextIndex = 0;
    setIndex(nextIndex);
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    let id = setTimeout(tick, 5000);
    return () => clearTimeout(id);
  }, [index]);

  if (hidden || headerBannerArray.length === 0) return null;

  const closeBanner = () => {
    setHidden(true);
    sessionStorage.setItem("demystBannerClosed", true);
  };

  return (
    <div className="header_banner">
      <section className="header_banner-page_circle-container">
        {headerBannerArray.map((banner, i) => (
          <button
            key={banner.text}
            onClick={() => setIndex(i)}
            className={`header_banner-page_circle-button ${i === index ? "active" : ""}`}
            aria-label={`Banner control button ${i}`}
          />
        ))}
      </section>
      <AnimatePresence>
        <div className="header_banner-content">
          {headerBannerArray.map((banner, i) => {
            if (i === index)
              return (
                <motion.div key={banner.text} {...animatePresenceConfig}>
                  {banner.type === "text-with-buttons" && (
                    <>
                      <p className="header_banner-content-text" aria-label="Banner content">
                        {banner.text}
                      </p>
                      <div className="header_banner-content-button_container">
                        {banner.buttons.map(button => (
                          <a
                            className="header_banner-content-button_container-button"
                            key={button.text}
                            href={button.href}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Banner link"
                          >
                            {button.text}
                          </a>
                        ))}
                      </div>
                    </>
                  )}
                  {banner.type === "link" && (
                    <a
                      className="header_banner-content-text underline"
                      href={banner.redirect_url}
                      aria-label="Banner description"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {banner.text}
                    </a>
                  )}
                </motion.div>
              );
          })}
        </div>
      </AnimatePresence>

      <button onClick={closeBanner} aria-label="Close banner" className="header_banner-close_icon">
        <img
          src={Close.src}
          alt="Close"
          title="Close this banner"
          height="12"
          width="12"
          loading="eager"
        />
      </button>
    </div>
  );
};
