import { linksConstants } from "../../constants/linksConstants";

export const headerMenu = {
  links: [
    {
      name: "Platform",
      url: linksConstants.PLATFORM,
      isNewTab: false,
    },
    {
      name: "Documentation",
      url: "https://docs.demyst.com/",
      isNewTab: true,
    },
    {
      name: "Discovery",
      url: "https://copilot.demyst.com/",
      isNewTab: true,
    },
    {
      name: "Powered By",
      url: linksConstants.POWERING,
      isNewTab: false,
    },
    {
      name: "About",
      url: linksConstants.ABOUT,
      isNewTab: false,
    },
  ],
  login_button: "Log In",
  login_button_target: linksConstants.LOGIN,
  platform_button: "Go to Platform",
  platform_button_target: linksConstants.DATA_CATALOG,
};
