import { linksConstants } from "../../constants/linksConstants";

import DemystLogo from "../assets/svg/DemystLogo.svg";
import DemystLogoWhite from "../assets/svg/DemystLogoWhite.svg";

import MenuWhite from "../assets/svg/header/MenuWhite.svg";
import MenuBlue from "../assets/svg/header/MenuBlue.svg";

export const specialColorSchemes = [
  {
    path: linksConstants.HOME,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
  {
    path: linksConstants.BLOGMAIN,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
  {
    directory: "blog",
    logo: DemystLogo,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
  },
  {
    directory: "third-party-data",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
  {
    directory: "external-data",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
  {
    directory: "info",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
  {
    directory: "lp",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent landing",
  },
  {
    directory: "data-api",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
  {
    directory: "webinars",
    background: "transparent",
    logo: DemystLogo,
    links: "blue",
    menu: MenuBlue,
  },

  {
    directory: "industries",
    links: "white",
    logo: DemystLogoWhite,
    menu: MenuWhite,
    background: "transparent",
  },
  {
    path: linksConstants.ENTERPRISE,
    links: "white",
    logo: DemystLogoWhite,
    menu: MenuWhite,
    background: "transparent",
  },
  {
    path: linksConstants.AWS_CASE_STUDY,
    logo: DemystLogo,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
  },
  {
    path: linksConstants.CAREERS,
    logo: DemystLogo,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
  },
  {
    path: linksConstants.PLATFORM,
    links: "white",
    menu: MenuWhite,
    logo: DemystLogoWhite,
    background: "transparent",
  },
  {
    path: linksConstants.ABOUT,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
    button_inverted: false,
  },
  {
    path: linksConstants.CONTACT,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
    button_inverted: false,
  },
  {
    path: linksConstants.REQUEST_DEMO,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent landing",
    button_inverted: false,
  },
  {
    path: linksConstants.REPORT,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
    button_inverted: false,
  },
  {
    path: linksConstants.RELEASES,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "purple",
    button_inverted: false,
  },
  {
    path: linksConstants.WEBINARS,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.PARTNERS,
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
    button_inverted: false,
  },
  {
    path: linksConstants.ADX_FINCRIME,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.ADX_DEMYST_PARTNERSHIP,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.ADX_DEMYST_EXCHANGE,
    links: "white",
    logo: DemystLogoWhite,
    menu: MenuWhite,
    background: "transparent",
  },
  {
    path: linksConstants.NEWSLETTER,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_KYB,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_KYC,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_PREFILL,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_BNPL,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_DATA_ENRICHMENT,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_LEAD_TARGETING,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_PORTFOLIO_MONITORING,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_CREDIT_BUREAU,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.USECASE_BUSINESS_ONBOARDING,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.PRICING,
    background: "transparent",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
  },
  {
    path: linksConstants.DSAR,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.CCPA,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.MANAGE_COOKIE,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.COOKIE_POLICY,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.SUBPROCESSOR_POLICY,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.TERMS,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.PRIVACY,
    links: "blue",
    menu: MenuBlue,
    background: "transparent",
    logo: DemystLogo,
  },
  {
    path: linksConstants.RESOURCES,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
    logo: DemystLogoWhite,
  },
  {
    directory: "platform",
    logo: DemystLogoWhite,
    links: "white",
    menu: MenuWhite,
    background: "transparent",
  },
];
