import { linksConstants } from "../../constants/linksConstants";

export const navMobile = {
  sections: [
    {
      name: "",
      urlList: [
        {
          title: "Platform",
          link: linksConstants.PLATFORM,
          image: "",
        },
        {
          title: "Documentation",
          link: "https://docs.demyst.com/o",
          image: "",
          external: true,
        },
        {
          title: "Discovery",
          link: "https://copilot.demyst.com/",
          image: "",
          external: false,
        },
        {
          title: "Powered By",
          link: linksConstants.POWERING,
          image: "",
        },
        {
          title: "Resources",
          link: linksConstants.RESOURCES,
          image: "",
        },
        {
          title: "About Us",
          link: linksConstants.ABOUT,
          image: "",
        },
      ],
    },
  ],
  login_button: "Log In",
  login_button_target: linksConstants.LOGIN,
  platform_button: "Go to Platform",
  platform_button_target: linksConstants.DATA_CATALOG,
};
