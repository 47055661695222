import { navMobile } from "../../datasets/navMobile";
import CloseButton from "../../assets/svg/CloseButton.svg";
import { LinkButton } from "../../views/components/LinkButton";
import { MainCtaButton } from "../../views/components/MainCtaButton";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import clsx from "clsx";

const AccordionItem = ({ section, scroll }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxHeight = 70 * section.urlList.length;

  return (
    <>
      <button
        id={`${section.name}-header`}
        aria-controls={`${section.name}-panel`}
        aria-expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        className="nav_mobile-section_title nav_mobile-section_title-button"
      >
        <p>{section.name}</p>
        <FiChevronDown className={clsx("arrow", isExpanded && "inverted")} />
      </button>
      <div
        className="nav_mobile-accordion-body"
        id={`${section.name}-panel`}
        aria-labelledby={`${section.name}-header`}
        aria-hidden={!isExpanded}
        style={{ maxHeight: isExpanded ? `${maxHeight}px` : 0 }}
      >
        {section.urlList.map(item => (
          <a
            key={`mobile-menu-${item.title}`}
            href={item.link}
            className="nav_mobile-item"
            onClick={() => scroll(item.link)}
          >
            {item.image !== null && item.image.src && <img src={item.image.src} alt="" />}
            <section className="nav_mobile-section_description">
              <p className="nav_mobile-title">{item.title}</p>
            </section>
          </a>
        ))}
      </div>
    </>
  );
};

export const NavMobile = ({ closeMenu, hideMainNav, isUserLoggedIn }) => {
  const scroll = link => {
    let current = window.location.pathname;
    if (current === "/platform" && link.indexOf("#") !== -1) {
      let hash = link.slice(link.indexOf("#") + 1);
      let element = document.getElementById(hash);
      if (element !== null) {
        let rect = element.getBoundingClientRect();
        let scrollTop = window.pageYOffset;
        let result = rect.top + scrollTop - 130;
        window.scrollTo({
          top: result,
          left: 0,
          behavior: "smooth",
        });
      }
    }
    closeMenu(false);
  };

  return (
    <section className="nav_mobile-background">
      <button className="nav_mobile-close" onClick={() => closeMenu(false)}>
        <img
          src={CloseButton.src}
          alt="Close menu button"
          title="Click here to close the navigation menu"
          loading="lazy"
        />
      </button>
      <nav className="nav_mobile-main">
        <div className="nav_mobile-buttons">
          {isUserLoggedIn ? (
            <LinkButton
              section="NavMobile"
              href={navMobile?.platform_button_target}
              variant="inverted"
            >
              {navMobile?.platform_button}
            </LinkButton>
          ) : (
            <>
              <LinkButton
                onClick={closeMenu}
                section="NavMobile"
                href={navMobile?.login_button_target}
                variant="inverted"
              >
                {navMobile?.login_button}
              </LinkButton>
              <MainCtaButton onClick={closeMenu} />
            </>
          )}
        </div>
        {!hideMainNav && (
          <div className="nav_mobile-sections">
            {navMobile?.sections.map((section, sectionIndex) =>
              !section.isAccordion ? (
                <div key={`nav-mobile-section-${sectionIndex}`} className="nav_mobile-section">
                  {section.name && <p className="nav_mobile-section_title">{section.name}</p>}

                  <div>
                    {section.urlList.map(item => (
                      <a
                        key={`mobile-menu-${item.title}`}
                        href={item.link}
                        className="nav_mobile-item"
                        onClick={() => scroll(item.link)}
                      >
                        {item.image?.src && <img src={item.image.src} alt="" />}
                        <section className="nav_mobile-section_description">
                          <p className="nav_mobile-title">{item.title}</p>
                        </section>
                      </a>
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  key={`nav-mobile-section-${sectionIndex}-accordion`}
                  className="nav_mobile-section nav_mobile-section-accordion"
                >
                  <AccordionItem section={section} scroll={scroll} />
                </div>
              ),
            )}
          </div>
        )}
      </nav>
    </section>
  );
};
