import Butter from "buttercms";

function shouldRetry(config) {
  config["__custom_retry_handling__"] = config["__custom_retry_handling__"] || { retries: 0 };

  if (config["__custom_retry_handling__"].retries < 3) {
    config["__custom_retry_handling__"].retries += 1;
    return true;
  } else {
    return false;
  }
}

// stolen from: https://github.com/softonic/axios-retry/blob/00439f4a9bde3cfe5f9c6f69b22af2b941d7c38e/es/index.mjs#L109
// and from: https://github.com/softonic/axios-retry/blob/00439f4a9bde3cfe5f9c6f69b22af2b941d7c38e/es/index.mjs#L234
function fixConfig(axios, config) {
  if (axios.defaults.agent === config.agent) {
    delete config.agent;
  }
  if (axios.defaults.httpAgent === config.httpAgent) {
    delete config.httpAgent;
  }
  if (axios.defaults.httpsAgent === config.httpsAgent) {
    delete config.httpsAgent;
  }

  config.transformRequest = [data => data];
}

function axiosHook(axios) {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { config } = error;

      if (!config) {
        // idk what would cause this to go missing, but they handle it so we should, too
        // ref: https://github.com/softonic/axios-retry/blob/00439f4a9bde3cfe5f9c6f69b22af2b941d7c38e/es/index.mjs#L207-L209
        console.info(`error from buttercms: ${error} (${error.config?.url})`);
        return Promise.reject(error);
      }

      if (shouldRetry(config)) {
        fixConfig(axios, config);
        console.info(`error from buttercms: ${error} (${error.config?.url}). retrying...`);
        return axios(config);
      } else {
        console.info(`error from buttercms: ${error} (${error.config?.url})`);
        return Promise.reject(error);
      }
    },
  );
}

const butter = Butter("db35569d3bb3739cf02d32a74a1b5f6dc6243d57", false, 12000, axiosHook);

export default butter;
