import clsx from "clsx";
import Link from "next/link";
import Demyst from "../assets/Demyst.svg";

export const LinkButton = ({
  autoWidth = false,
  demystIcon = false,
  hidden,
  isExternal,
  onClick,
  variant = "primary",
  size = "lg",
  disabled,
  to,
  children,
  className,
  noStyle = false,
  ...props
}) => {
  const classes =
    noStyle !== true
      ? clsx(
          "button-component",
          variant === "inverted" && "button-component-inverted",
          variant === "secondary" && "button-component-secondary",
          variant === "secondary-alt" && "button-component-secondary-alt",
          hidden && "hidden",
          autoWidth && "button-component-autoWidth",
          size && `button-component-size-${size}`,
          disabled && "button-component-disabled",
          className,
        )
      : className;

  return (
    <Link
      href={to}
      className={classes}
      onClick={e => {
        onClick && onClick(e);
      }}
      isExternal={isExternal}
      {...props}
    >
      {noStyle !== true ? <span>{children}</span> : children}
      {demystIcon && <img src={Demyst.src} alt="" width="16" height="22" loading="eager" />}
    </Link>
  );
};
