import { useState } from "react";

import { useScrollTop } from "@wojtekmaj/react-hooks";
import clsx from "clsx";

import { headerMenu } from "../../datasets/headerMenu";
import { LinkButton } from "../../views/components/LinkButton";
import { MainCtaButton } from "../../views/components/MainCtaButton";

import { NavDesktop } from "./NavDesktop";
import { NavMobile } from "./NavMobile";

import { specialColorSchemes } from "../../datasets/headerColors";

import Menu from "../../assets/svg/header/Menu.svg";
import DemystLogo from "../../assets/svg/DemystLogo.svg";
import { Banner } from "./Banner";

import Link from "next/link";
import usePathChange from "../../../utils/hooks/usePathChange";
import { useUser } from "../../../redux/Provider";

export const Header = ({ showBanner = true, showNav = true }) => {
  const pathname = usePathChange();
  const scrollTop = useScrollTop();
  const user = useUser();
  const isUserLoggedIn = user && !!user.user;
  const [mobileNavOpen, changeMobileNavOpen] = useState(false);
  const [isPlatformMenuOpen, setIsPlatformMenuOpen] = useState(false);
  const [isResourcesMenuOpen, setIsResourcesMenuOpen] = useState(false);

  const isLanding = !showNav;

  const isScrolledTop = scrollTop === 0;

  const specialColorScheme = specialColorSchemes.find(({ path, directory }) => {
    if (path) return path === pathname;
    if (!path && directory) return pathname.startsWith(`/${directory}/`);

    return false;
  });

  const allMenusClosed = !isResourcesMenuOpen && !isPlatformMenuOpen;
  const headerBackground = allMenusClosed && isScrolledTop && specialColorScheme?.background;
  const logo = allMenusClosed && isScrolledTop && specialColorScheme?.logo;
  const menu = allMenusClosed && isScrolledTop && specialColorScheme?.menu;
  const linkColor = allMenusClosed && isScrolledTop && specialColorScheme?.links;

  const buttonInverted = allMenusClosed && isScrolledTop && specialColorScheme?.button_inverted;

  const openMenu = event => {
    if (event.currentTarget.getAttribute("data-name") === "Resources") {
      setIsResourcesMenuOpen(true);
    } else if (event.currentTarget.getAttribute("data-name") === "Platform") {
      setIsPlatformMenuOpen(true);
    }
  };

  const closeMenu = e => {
    e.persist();
    setIsResourcesMenuOpen(false);
    setIsPlatformMenuOpen(false);
  };

  const isLinkActive = link => {
    if (link.url) {
      if (link.url === pathname) return true;
    }

    if (link.urlList) {
      let availableLinks = link.urlList.map(l => l.link);
      if (availableLinks.includes(pathname)) return true;
    }

    if (link.items) {
      let availableLinks = link.items.map(item => item.urlList.map(l => l.link)).flat(1);
      if (availableLinks.includes(pathname)) return true;
    }

    return false;
  };

  return (
    <>
      <header className={clsx("layout-header-full", isLanding && "layout-header-full-landing")}>
        {showBanner && <Banner />}
        <div
          className={clsx(
            "layout-header-full-header",
            !isLanding && headerBackground,
            isLanding && specialColorScheme?.background,
            isScrolledTop && "on-top",
          )}
        >
          <section className="section-layout">
            <section className="layout-header-main">
              <Link href={"/"} className="layout-header-logo" title="Return home">
                <img
                  src={!isLanding ? logo?.src || DemystLogo.src : specialColorScheme?.logo.src}
                  className="layout-header-logo-img"
                  alt="Demyst"
                  loading="eager"
                  height="40"
                  width="162"
                />
              </Link>
              {!isLanding && (
                <ul className="layout-header-nav">
                  {headerMenu.links.map(link => (
                    <li
                      key={link.name}
                      data-name={link.name}
                      className={clsx(
                        "layout-header-nav-item",
                        isLinkActive(link) && "layout-header-nav-item--active",
                      )}
                      onMouseEnter={openMenu}
                      onMouseLeave={closeMenu}
                    >
                      {link.url ? (
                        <Link
                          href={link.url}
                          className={clsx("layout-header-link", linkColor)}
                          target={link.isNewTab ? "_blank" : "_self"}
                          dangerouslySetInnerHTML={{ __html: link.name }}
                        />
                      ) : (
                        <>
                          <Link
                            className={clsx("layout-header-link", linkColor)}
                            dangerouslySetInnerHTML={{ __html: link.name + " ▾" }}
                            href={link.defaultUrl}
                            onClick={closeMenu}
                          />
                          {link.name === "Resources" && (
                            <NavDesktop
                              name={link.name}
                              description={link.description}
                              data={link.urlList}
                              closeMenu={closeMenu}
                              isOpen={isResourcesMenuOpen}
                            />
                          )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {!isLanding &&
                (isUserLoggedIn ? (
                  <section className="layout-header-buttons">
                    <LinkButton
                      variant={!buttonInverted ? "inverted" : "primary"}
                      href={headerMenu.platform_button_target}
                      autoWidth
                      size="md"
                    >
                      {headerMenu.platform_button}
                    </LinkButton>
                  </section>
                ) : (
                  <section className="layout-header-buttons">
                    <LinkButton
                      variant={!buttonInverted ? "inverted" : "primary"}
                      section="Header"
                      href={headerMenu.login_button_target}
                      autoWidth
                      size="md"
                    >
                      {headerMenu.login_button}
                    </LinkButton>
                    <MainCtaButton autoWidth size="md" />
                  </section>
                ))}

              {!isLanding && (
                <button className="layout-header-menu" onClick={() => changeMobileNavOpen(true)}>
                  <img
                    src={menu?.src || Menu.src}
                    alt="Menu"
                    height="18"
                    width="24"
                    title="Click to open menu"
                    loading="eager"
                  />
                </button>
              )}
              {mobileNavOpen && (
                <NavMobile isUserLoggedIn={isUserLoggedIn} closeMenu={changeMobileNavOpen} />
              )}
            </section>
          </section>
        </div>
      </header>
    </>
  );
};
