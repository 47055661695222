import PropTypes from "prop-types";
import clsx from "clsx";
import Image from "next/image";

export const NavDesktop = ({ closeMenu, data, isOpen, name, description }) => {
  const ItemElement = ({ item }) => {
    return (
      <>
        <Image src={item.image.src} alt="" width="48" height="48" unoptimized />
        <div>
          <p className="nav_desktop-title">{item.title}</p>
          <p className="nav_desktop-description">{item.description}</p>
        </div>
      </>
    );
  };

  return (
    <section className={clsx("nav_desktop-background", isOpen && "hover")}>
      <nav aria-label={`nav_desktop-main-${name || "main"}`} className="nav_desktop-main">
        <div className="section-layout">
          <div className="nav_desktop-menu">
            <div className="nav_desktop-infobox">
              <p className="nav_desktop-infobox-title">{name}</p>
              <p className="nav_desktop-infobox-paragraph">{description}</p>
            </div>
            <div className="nav_desktop-table">
              {["Resources"].includes(name) &&
                data.map(item =>
                  item.external ? (
                    <a
                      key={`desktop-menu-${item.title}`}
                      className="nav_desktop-item"
                      onClick={e => closeMenu(e)}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ItemElement item={item} />
                    </a>
                  ) : (
                    <a
                      key={`desktop-menu-${item.title}`}
                      className="nav_desktop-item"
                      href={item.link}
                      onClick={e => closeMenu(e)}
                    >
                      <ItemElement item={item} />
                    </a>
                  ),
                )}
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

NavDesktop.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
