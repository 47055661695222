import { LinkButton } from "./LinkButton";
import { sendGaEvent } from "../../../config/userTracking";
import { linksConstants } from "../../../constants/linksConstants";

export const MainCtaButton = ({ ...props }) => {
  const sendCTAGaEvent = () => {
    sendGaEvent({
      action: `CTA Click - Contact Us`,
      category: "Main CTA",
      label: `/contact `,
    });
  };

  return (
    <LinkButton
      demystIcon
      variant="secondary"
      onClick={sendCTAGaEvent}
      href={linksConstants.REQUEST_DEMO}
      {...props}
    >
      Request a Demo
    </LinkButton>
  );
};
